.first-row {
	background-color: transparent;
    height: 80px;
    color: #36BF71;
    font-size: large;
    font-weight: bold;
	align-items: center;

	a {
		font-size: small;
	}
}

.italy-flag {
	max-height: 3px;
	overflow: hidden;
	background-color: #36BF71;
}
.green-bg {
	background-color: transparent;
}
.red-bg {
	background-color: transparent;
}

.last-row {
	display: none;
	// background-color: #AB8f69;
	// height: 60px;
	// color: white;
	// font-size: x-large;
	// font-weight: bold;
    // align-items: center;
}